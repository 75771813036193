<template>
    <div class="wishPublish">
        <van-field class="wishInput" :border="false" v-model="publishContent" rows="8" autosize type="textarea"
            maxlength="150" placeholder="请输入问题（至少三个字）～" show-word-limit />

        <div class="exampleTitle">一个好的提问：</div>
        <div class="example">* 问题是什么？你想得到什么帮助？</div>
        <div class="example">* 例：求片神宫寺奈绪 URE-067 义父～裕美的午后 ？</div>
        <div class="example">* 例：如图求此片的番号 ？</div>

        <div class="addText">添加图片</div>
        <UploaderFile ref="uploadImg" class="upload" @uploadSuccess="uploadSuccess" @delImg="delImg" :multiple="true" maxCount="6" />
        <div class="paramItem">
            <div class="title">悬赏金币设置</div>
            <div class="param" @click="$emit('showPopups')">
                <svg-icon class="goldIcon" iconClass="gold" v-if="bountyGold"></svg-icon>{{bountyGold || '免费'}}
            </div>
        </div>

        <div class="submit" @click="submit">立即发布</div>

        <!-- <RewardPopuos :isRewardShow="isRewardShow" :title="'设置价格'" :amountList="amountList" @confirm="confirm" @close="isRewardShow=false"/> -->
    </div>
</template>

<script>
    import {
        Field,
        Toast
    } from "vant";
    import UploaderFile from "@/components/UploaderFile";
    import {
        addQuestion
    } from "@/api/mine";
    // import RewardPopuos from "@/components/RewardPopuos"
    export default {
        name: 'WishPublish',
        components: {
            UploaderFile,
            // RewardPopuos,
            [Field.name]: Field,
        },
        data() {
            return {
                publishContent: "", //发布内容
                bountyGold: 0, //悬赏金币
                imgList: [],
            }
        },
        methods: {
            setAmount(amount) {
                this.bountyGold = amount;
            },
            // 上传成功回调
            uploadSuccess(imgUrl) {
                // console.log(imgUrl);
                this.imgList.push(imgUrl);
            },
            // 图片删除回调
            delImg(index) {
                this.imgList.splice(index, 1)
            },
            // 发布
            async submit() {
                if(!this.publishContent) {
                    Toast('请输入问题内容');
                    return;
                }
                let req = {
                    question: this.publishContent,
                    images: this.imgList,
                    bountyGold: this.bountyGold,
                }
                this.$store.commit('app/SET_LOADING', true);
                try {
                    let res = await this.$Api(addQuestion, req);
                    this.$store.commit('app/SET_LOADING', false);
                    if (res.code === 200) {
                        this.publishContent = "";
                        this.imgList = [];
                        this.bountyGold = 0;
                        this.$refs.uploadImg.clearImg();
                        Toast('发布成功');
                        return;
                    }
                    Toast('发布失败，请稍后再试');
                } catch (error) {
                    this.$store.commit('app/SET_LOADING', false);
                    Toast('发布失败，请稍后再试');
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
    .wishPublish {
        transform: scale(1);
        padding-top: 14px;
        height: 100%;
        overflow-y: auto;
        // height: calc(100% - 46px);
        position: relative;
      -webkit-overflow-scrolling: touch;

        .wishInput {
            background: #1e1e1e;

            // color: #fff;
            /deep/ .van-field__word-limit {
                color: #5d6472;
            }

            /deep/ .van-field__control {
                color: #fff;
                font-size: 16px;
            }

            ::placeholder {
                color: #939bac;
            }
        }

        .exampleTitle {
            font-size: 18px;
            margin: 24px 0 18px;
        }

        .example {
            color: #ffffff50;
            font-size: 16px;
            margin-bottom: 6px;
        }

        .addText {
            font-size: 18px;
            margin: 34px 0 10px;
        }

        .upload {
            margin-bottom: 20px;
        }

        .paramItem {
            margin-bottom: 82px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-size: 16px;
            }

            .param {
                font-size: 18px;
                display: flex;
                align-items: center;

                .goldIcon {
                    width: 12.5px;
                    height: 12.5px;
                    margin-right: 3px;
                }
            }
        }

        .submit {
            width: 228px;
            height: 44px;
            line-height: 44px;
            border-radius: 44px;
            text-align: center;
            background-image: linear-gradient(to right, #ff7f0f, #e38825);
            margin: 0 auto 36px;
            font-size: 16px;
            // position: absolute;
            // left: 50%;
            // bottom: 36px;
            // transform: translateX(-50%);
        }
    }
</style>
