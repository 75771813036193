<template>
  <div class="myQuestionList">
    <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
                 @onRefresh="onRefresh" :isHigehtMax="true" :error="error" :isNoData="isNoData">
      <div tag="div" class="questionItem" v-for="item,index in list" :key="index" @click="jumpDetails(item)">
        <div class="topBox">
          <div class="flex" style="width: 100%">
            <div class="goldBox" v-if="item.bountyGold">
              <svg-icon class="bountyIcon" iconClass="gold"></svg-icon>
              {{ item.bountyGold }}
            </div>
            <div class="questionDesc">{{ item.question }}</div>
          </div>
          <svg-icon class="finishIcon" iconClass="finish" v-if="item.isAdoption"></svg-icon>
        </div>

        <div class="questionInfo">
          <svg-icon class="questionIcon" iconClass="question_time"></svg-icon>
          <div class="mr8">{{ item.createdAt | dateago }}</div>
          <svg-icon class="questionIcon" iconClass="question_eye"></svg-icon>
          <div>{{ item.lookCount | watchCount }}</div>
        </div>
      </div>
    </PullRefresh>
  </div>
</template>

<script>
import PullRefresh from "@/components/PullRefresh";
import {
  queryQuestionList
} from "@/api/mine";
import {
  getSessionItem
} from "@/utils/longStorage";

export default {
  name: "myQuestionList",
  components: {
    PullRefresh,
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页数
      pageSize: 10, // 条数
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
    }
  },
  created() {
    this.pageNumber = 1, // 页数
    this.getList("refresh");
  },
  // activated() {
  //   this.pageNumber = 1, // 页数
  //   this.getList("refresh");
  // },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: String(this.pageNumber),
        pageSize: String(this.pageSize),
        uid: JSON.parse(getSessionItem("userInfo")).uid,
      }
      try {
        let res = await this.$Api(queryQuestionList, req)
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }

    },
    // 加载更多
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++
      }
      this.getList();
    },
    // 刷新列表
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.getList('refresh');
    },
    // 跳转详情
    jumpDetails(item) {
      let data = window.btoa(window.encodeURI(JSON.stringify(item)));
      this.$router.push({
        path: '/questionDetails',
        query: {
          data: data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.myQuestionList {
  height: 100%;
  // height: calc(100% - 46px);
  // overflow-y: auto;
  padding-top: 14px;
  box-sizing: border-box;

  .questionItem {
    height: 83px;
    padding: 20px 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1e1e1e;

    .topBox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .goldBox {
        display: flex;
        align-items: center;
        margin-right: 7px;
        font-size: 16px;

        .bountyIcon {
          width: 12.5px;
          height: 12.5px;
          margin-right: 3px;
        }
      }

      .finishIcon {
        width: 20px;
        height: 20px;
      }
    }

    .questionDesc {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
    }

    .questionInfo {
      display: flex;
      align-items: center;
      font-size: 12px;

      .questionIcon {
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }
    }
  }
}

.mr8 {
  margin-right: 8px;
}
</style>
