<template>
    <div class="wishList">
        <div class="navBar">
            <div @click="$router.go(-1)">
                <svg-icon class="leftBtn" iconClass="back"></svg-icon>
            </div>
            <div class="title">我要求片</div>
            <div class="rightbtn"></div>
        </div>
        <!-- <div class="bankBtn">123213</div> -->
        <!-- <div class="bankBtn" @click="$router.go(-1)">
            <svg-icon style="width: 100%; height: 100%" iconClass="back"></svg-icon>
        </div> -->

        <van-tabs v-model="activeName" background="#15151500" title-active-color="#ffffff"
            title-inactive-color="#737a8b" class="vant_tabs" animated :swipeable="true">
            <van-tab title="问题">
                <QuestionList />
            </van-tab>
            <van-tab title="发布">
                <WishPublish ref="wishpublish" @showPopups="showPopups" />
            </van-tab>
            <van-tab title="我的">
                <MyQuestionList />
            </van-tab>
        </van-tabs>

        <van-popup v-model="showPop" class="setAmoutBox" @close="close">
            <div class="popupsBox">
                <div class="popTitle">设定价格</div>
                <div class="amountList">
                    <div class="amountItem" :class="{activeAmout: activeAmount == i}" v-for="i in amountList" :key="i"
                        @click="selectAmout(i)">
                        <svg-icon class="goldIcon" icon-class="gold" />{{i}}
                    </div>
                    <div class="amountItem">
                        <van-field type="number" v-model="inpAmount" placeholder="其他金额" @focus="inputAmount" />
                    </div>
                </div>
                <div class="balance">钱包余额：{{balance}}</div>
                <div class="popBtn" @click="setAmount">确定</div>
            </div>
            <div class="closeBtn" @click="showPop = false">
                <svg-icon class="closeIcon" icon-class="y_close" />
            </div>
        </van-popup>
        <!-- <van-tabs v-model="activeName" @change="changeTab" background="#15151500" title-active-color="#ffffff"
            title-inactive-color="#737a8b" class="vant_tabs" animated :swipeable="true">
            <van-tab :title="item.name" v-for="item in tabs" :key="item.path" :name="item.path">
                <router-view/>
            </van-tab>
        </van-tabs> -->
    </div>
</template>

<script>
    import {
        Tab,
        Tabs,
        Popup,
        Field,
        Toast
    } from "vant";
    import QuestionList from "./questionList"
    import WishPublish from "./wishPublish"
    import MyQuestionList from "./myQuestionList"
    export default {
        name: "wishList",
        components: {
            QuestionList,
            WishPublish,
            MyQuestionList,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Popup.name]: Popup,
            [Field.name]: Field,
        },
        data() {
            return {
                activeName: 0,
                showPop: false, // 设置金额弹窗显隐
                amountList: [2, 5, 10, 50, 100], // 默认金额列表
                activeAmount: '', // 选中金额
                inpAmount: '', // 输入金额
                balance: 0, // 钱包余额
                // tabs: [
                //     {
                //         refStr: "wishList",
                //         path: "/wishList/questionList",
                //         name: "问题",
                //     },
                //     {
                //         path: "/wishList/wishPublish",
                //         name: "发布",
                //     },
                // ],
            }
        },
      methods: {
            // 切换tab
            // changeTab(name) {
            //     this.$router.replace(name);
            // },
            async showPopups() {
                await this.$store.dispatch("user/getWallet");
                let walletInfo = this.$store.getters.walletInfo;
                this.balance = (walletInfo.amount || 0 + walletInfo.income || 0).toFixed(2);
                this.showPop = true;
            },
            // 获取焦点清除选中金额
            inputAmount() {
                this.activeAmount = "";
            },
            // 选择金额
            selectAmout(amout) {
                this.activeAmount = amout;
                this.inpAmount = "";
            },
            // 设置金额
            setAmount() {
                if (!this.inpAmount && !this.activeAmount) {
                    Toast("请输入或选择打赏金额");
                    return
                }
                if (Number(this.inpAmount) && this.inpAmount > this.balance) {
                    Toast("余额不足，请前往充值");
                    return
                }
                if (this.activeName && this.activeName > this.balance) {
                    Toast("余额不足，请前往充值");
                    return
                }
                let amount = Number(this.inpAmount) || this.activeAmount;
                this.$refs.wishpublish.setAmount(Number(amount))
                this.showPop = false;
            },
            // 关闭弹窗
            close() {
                this.activeAmount = "";
                this.inpAmount = "";
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wishList {
        padding: 0 16px;
        height: 100%;
        overflow: hidden;

        .navBar {
            height: 46px;
            // margin-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .leftBtn {
                width: 24px;
                height: 24px;
            }

            .title {
                font-size: 20px;
            }

            .rightBtn {
                width: 24px;
                height: 24px;
            }
        }

        // .bankBtn {
        //     position: fixed;
        //     top: 14px;
        //     left: 16px;
        //     z-index: 9;
        //     width: 18px;
        //     height: 18px;
        // }

        .vant_tabs {
            height: 100%;
            // margin-top: 10px;

            /deep/ .van-tabs__wrap {
                // width: 160px;
                height: 30px;
                margin: 0 auto 4px;
            }

            /deep/ .van-tabs__line {
                width: 20px;
                height: 4px;
                background: #e38825;
            }

            /deep/ .van-tab__text {
                font-size: 18px;
            }


            /deep/ .van-tab__pane {
                height: 100%;
            }

            /deep/ .van-tab__pane-wrapper {
                height: 100%;
            }

            /deep/ .van-tabs__track {
                height: 100%;
            }

            /deep/ .van-tabs__content {
                height: calc(100% - 80px);
                // background: red;
            }
        }

        .setAmoutBox {
            background: #00000000;

            .popupsBox {
                width: 294px;
                // height: 264px;
                padding: 18px 15px 24px;
                border-radius: 17px;
                box-sizing: border-box;
                background: linear-gradient(#fff2e6, #fff2e6);
                color: #171717;

                .popTitle {
                    font-size: 16px;
                    text-align: center;
                    margin-bottom: 21px;
                }

                .amountList {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-row-gap: 8px;
                    grid-column-gap: 8px;

                    .amountItem {
                        width: 81px;
                        height: 37px;
                        font-size: 18px;
                        border-radius: 4px;
                        // border: 1px solid #ff7600;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fff;

                        /deep/ .van-field {
                            width: 100%;
                            height: 100%;
                            padding: 0;
                            margin: 0;
                            display: flex;
                            align-items: center;
                        }

                        /deep/ .van-field__control {
                            text-align: center;
                        }
                    }

                    .activeAmout {
                        color: #fff;
                        background: #ff7600;
                    }

                    .goldIcon {
                        margin-right: 3px;
                    }

                }

                .balance {
                    font-size: 12px;
                    margin-top: 16px;
                    color: #17171760;
                }

                .popBtn {
                    width: 166px;
                    height: 36px;
                    line-height: 36px;
                    border-radius: 36px;
                    margin: 24px auto 0;
                    color: #fff;
                    text-align: center;
                    background: #ff7426;
                }
            }

            .closeBtn {
                margin-top: 24.5px;
                display: flex;
                align-items: center;
                justify-content: center;

                .closeIcon {
                    width: 33.2px;
                    height: 33.2px;
                }
            }
        }

    }
</style>
